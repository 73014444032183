const stripSensitiveData = (
    data: unknown,
    sensitiveFields: string[],
): unknown => {
    if (!data || typeof data !== "object") return data;

    if (Array.isArray(data)) {
        return data.map((item) => stripSensitiveData(item, sensitiveFields));
    }

    const stripped = { ...data };
    for (const [key, value] of Object.entries(stripped)) {
        if (
            sensitiveFields.some(
                (field) => key.toLowerCase() === field.toLowerCase(),
            )
        ) {
            delete stripped[key];
        } else if (typeof value === "object" && value !== null) {
            stripped[key] = stripSensitiveData(value, sensitiveFields);
        }
    }
    return stripped;
};

export default stripSensitiveData;
