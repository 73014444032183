import React from "react";
import clsx from "clsx";
import { CheckIcon, XMarkIcon } from "@heroicons/react/24/solid";
import { Spinner } from "@/ui/atoms/Spinner";

interface GradatedProgressProps {
    steps: string[];
    currentStep: number;
    showSpinner?: boolean;
    isError?: boolean;
    className?: string;
}

export const GradatedProgress: React.FC<GradatedProgressProps> = ({
    steps,
    currentStep,
    isError,
    showSpinner = true,
    className = "",
}) => {
    const progress = (currentStep / (steps.length - 1)) * 100;

    return (
        <div className={clsx("w-full", className)}>
            <div
                className={clsx(
                    "relative h-2 w-full overflow-hidden rounded-full bg-slate-100/20",
                )}
            >
                {/* Progress bar */}
                <div
                    className={clsx(
                        "absolute h-full rounded-full bg-brand-400 transition-all duration-500 ease-in-out",
                    )}
                    style={{
                        width: `${progress === 100 ? 100 : progress + 3}%`,
                    }}
                />
            </div>

            {/* Step indicators */}
            <div className="relative mt-4 flex justify-between">
                {steps.map((step, index) => {
                    const isCurrent = index === currentStep;
                    const isLast = index === steps.length - 1;
                    const isCompleted =
                        index < currentStep || currentStep === steps.length - 1;

                    return (
                        <div
                            key={index}
                            className="flex items-center gap-2 transition-[width] duration-500 ease-in-out"
                        >
                            {(!isCurrent || isLast) && (
                                <CheckIcon
                                    className={clsx(
                                        "fade-container relative z-10 size-4",
                                        !isCompleted && "invisible opacity-0",
                                    )}
                                />
                            )}

                            {isCurrent && showSpinner && <Spinner />}

                            {isError && isCurrent && (
                                <XMarkIcon className="fade-container size-4 self-end fill-red-500 stroke-red-500" />
                            )}

                            <p
                                className={clsx(
                                    "ui-label mt-1 transition-colors duration-300",
                                    isError && isCurrent && "text-red-500",
                                )}
                            >
                                {step}
                            </p>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};
