import {
    useLocation,
    useNavigate,
    useParams,
    useSearchParams,
} from "react-router-dom";
import qs from "query-string";
import { useCallback, useMemo } from "react";
import { Router, RouterSearch } from ".";

export const useRouter = (): Router => {
    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate();
    const location = useLocation();
    const params = useParams();
    const search = useMemo(() => {
        return qs.parse(location.search);
    }, [location.search]) as any as RouterSearch;
    const updateSearch = useCallback(
        (key: string, value: string) => {
            const newSearchParams = new URLSearchParams(searchParams);
            newSearchParams.set(key, value);
            setSearchParams(newSearchParams);
        },
        [setSearchParams, searchParams],
    );
    const removeSearchParam = useCallback(
        (key: string) => {
            const newSearchParams = new URLSearchParams(searchParams);
            newSearchParams.delete(key);
            setSearchParams(newSearchParams);
        },
        [setSearchParams, searchParams],
    );
    const getPathWithUpdatedSearch = useCallback(
        (key: string, value: string) => {
            const newSearchParams = new URLSearchParams(searchParams);
            newSearchParams.set(key, value);
            const newSearch = newSearchParams.toString();
            return `${location.pathname}${newSearch ? `?${newSearch}` : ""}`;
        },
        [location.pathname, searchParams],
    );

    return {
        ...location,
        asPath: location.pathname + location.search,
        search,
        searchString: location.search,
        params,
        navigate,
        updateSearch,
        removeSearchParam,
        getPathWithUpdatedSearch,
    };
};
