import { UserRole } from "@/entities/User/User.model";
import { Spinner } from "@/ui";
import { useRouter } from "@/utils";
import { useAuth0 } from "@auth0/auth0-react";
import { useEffect } from "react";
import { Navigate } from "react-router-dom";

function SignupRedirect() {
    const { loginWithRedirect } = useAuth0();
    const { params } = useRouter();

    useEffect(() => {
        if (params.role === "company") {
            loginWithRedirect({
                authorizationParams: {
                    redirect_uri:
                        process.env.AUTH0_LOGIN_REDIRECT_URI +
                        `/post-signup/${UserRole.COMPANY}`,
                    screen_hint: "signup",
                },
            });
        } else if (params.role === "fund") {
            loginWithRedirect({
                authorizationParams: {
                    redirect_uri:
                        process.env.AUTH0_LOGIN_REDIRECT_URI +
                        `/post-signup/${UserRole.FUND}`,
                    screen_hint: "signup",
                },
            });
        }
    }, [params.role, loginWithRedirect]);

    if (!params.role || !["company", "fund"].includes(params.role)) {
        return <Navigate to="/raise-or-invest" />;
    }

    return <Spinner fullScreen />;
}

export default SignupRedirect;
