import { InlineNotification, NotificationType, Spinner } from "@/ui";
import { useApiQuery } from "@/api";
import { asPage } from "@/utils/navigation";
import { getPublicProfile } from "@/entities/CompanyProfile";
import { Profile } from "@/features/Profile";

function PublicProfile() {
    const { data: profile, isLoading } = useApiQuery(getPublicProfile, {
        waitForSession: false,
    });

    if (!profile && isLoading) return <Spinner fullScreen />;

    if (profile) return <Profile.Content {...profile} isPrivate={false} />;

    return (
        <InlineNotification
            type={NotificationType.WARNING}
            className="translate-y-12"
        >
            There was an error fetching your data. Try refreshing the page or
            contacting support.
        </InlineNotification>
    );
}

export default asPage(PublicProfile, {
    testId: "public:profile-page",
    title: "Want to learn more? Log in or sign up to learn more about this opportunity",
    variant: "public",
});
