import {
    CompanyType,
    CompanyContactStatus,
    CompanyAnalyticsStatus,
    BusinessType,
    CompanySegment,
    OnboardingStatus,
    CompanyFileDocumentType,
    FileStatus,
} from "./Company.constants";
import { CapitalApplication } from "@/entities/CapitalApplication/CapitalApplication.types";
import { USStates } from "@/constants";

export type SignupStatus = {
    hasBasicBusinessInfo: boolean;
    hasFundraisingHistory: boolean;
    hasFundraisingGoals: boolean;
    complete: boolean;
};

export type CompanyContact = {
    id: number;
    firstName: string;
    position: string;
    lastName: string;
    email: string;
    phone: string;
    isPrimaryContact: boolean;
    status: CompanyContactStatus;
    acceptedCompanyCommunicationsOptIn: boolean | null;
    channel: string;
};

export type CompanySearchResult = {
    id: number;
    name: string;
    type: CompanyType[];
};

export type Company = {
    id: number;
    uuid: string;
    name: string;
    street?: string;
    city?: string;
    zipCode?: string;
    ein?: string;
    duns?: string;
    notes?: string;
    website?: string;
    description?: string | null;
    publicDescription?: string | null;
    createdAt: string;
    acceptedTos: boolean;
    acceptedTosOn: string;
    acceptedPrivacyPolicy: boolean;
    acceptedPrivacyPolicyOn: string;
    acceptedFeeTos: boolean;
    acceptedFeeTosOn: string;
    acceptedNda: boolean;
    acceptedNdaOn: string;
    updatedAt: string;
    country: string | undefined;
    type: CompanyType[];
    businessType?: BusinessType;
    businessTypeForSyndication?: BusinessType;
    capitalApplication: CapitalApplication;
    contact?: CompanyContact;
    tags: { label: string; value: string }[];
    labelingCompleted: boolean;
    syndicationInvestor: any;
    state: USStates;
    frozenCompanyAnalysisId?: number | undefined;
    activationStatus: {
        hasCompanyConfiguredConnector: boolean;
        hasCompanyConfiguredDataRoom: boolean;
        missingFileTypesInDataroom: string[];
    };
    signupStatus: SignupStatus;
    // charts?: CompanyChart[];
    analyticsStatus: CompanyAnalyticsStatus;
    segment?: CompanySegment;
    pendingMndaSignOffline?: string;
};

export type OnboardingState = {
    status: OnboardingStatus;
    emailVerified: boolean;
    basicInfoComplete: boolean;
    aboutYourBusinessComplete: boolean;
    unsupportedCountry: boolean;
    shareYourGoalsComplete: boolean;
    fundraisingHistoryComplete: boolean;
    profileComplete: boolean;
    MNDASigned: boolean;
    dataRoomComplete: boolean;
    isComplete: boolean;
    organizationId?: string;
    userId: string;
};

export type CompanySignupPayload = {
    companyPosition?: string;
    companyName?: string;
    website?: string;
    location?: string;
    country: string;
    state?: string;
    capitalApplication?: Partial<CapitalApplication>;
};

export type CompanyFile = {
    type: string;
    documentType: CompanyFileDocumentType;
    id: number;
    companyId: number;
    company: number;
    dataSourceUrl: string;
    ext: string;
    name: string;
    download: string;
    isExternal: boolean;
    createdBy: string;
    createdAt: string;
    updatedAt: string;
    ingestedAt: string;
    meta?: any;
    customerTapeMapping: any;
    source: CompanyFileSource;
    status: FileStatus;
    ingestionStatus: FileStatus;
    potentiallyInvalid?: boolean;
};
type CompanyFileSource = { url: string; path: string };
