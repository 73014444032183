import clsx from "clsx";
import React from "react";

export function CenteredLayout({
    title,
    description,
    graphic,
    children,
}: {
    title: string;
    description?: string | (() => React.ReactNode) | null;
    graphic?: React.ReactNode;
    children?: React.ReactNode;
}) {
    return (
        <main
            className={clsx(
                "px-6 py-24",
                "grid h-[99vh] place-items-center",
                "sm:py-32 lg:px-8",
            )}
        >
            <div className="flex flex-col items-center text-center">
                {graphic}
                <h1 className={clsx("display-m", "sm:display-s")}>{title}</h1>
                {description &&
                    (typeof description === "function" ? (
                        React.Children.map(description(), (child) => {
                            if (
                                React.isValidElement(child) &&
                                child.type === "p"
                            ) {
                                return React.cloneElement(child, {
                                    ...child.props,
                                    className: clsx(
                                        child.props.className,
                                        "body-s mt-6 w-[672px]",
                                    ),
                                });
                            }
                            return child;
                        })
                    ) : (
                        <p className="body-s mt-6 max-w-[672px]">
                            {description}
                        </p>
                    ))}
                <div
                    className={clsx(
                        "mt-10 w-full",
                        "flex flex-col items-center justify-center gap-x-6",
                    )}
                >
                    {children}
                </div>
            </div>
        </main>
    );
}
