import { useApiMutation } from "@/api";
import { useToken } from "@/entities/Session/useSession/useToken";
import { createUser } from "@/entities/User/User.mutations";
import { Spinner } from "@/ui";
import { useRouter } from "@/utils";
import { useAuth0 } from "@auth0/auth0-react";
import { useQueryClient } from "@tanstack/react-query";
import { useEffect } from "react";

function PostSignupPage() {
    const router = useRouter();
    const token = useToken();
    const auth0 = useAuth0();
    const cache = useQueryClient();

    const {
        mutate: create,
        isPending,
        data: createdUser,
    } = useApiMutation(createUser, {
        waitForSession: false,
        onSuccess: (data) => {
            cache.setQueryData(["/session"], data);
            router.navigate(`/signup-complete`);
        },
    });

    const { role } = router.params;

    useEffect(() => {
        if (auth0.user?.sub && auth0.user?.email && token.data && role) {
            if (!isPending && !createdUser) {
                create({
                    email: auth0.user.email,
                    auth0Id: auth0.user.sub,
                    token: token.data,
                    role: Array.isArray(role) ? role[0] : role,
                });
            }
        }
    }, [role, auth0.user?.sub, auth0.user?.email, token.data, isPending]);

    // TODO HUM-2879: if !intent open a modal or show component
    // to get the intent from user (fundraise or invest)
    // if (!intent || (!isLoading && !isAuthenticated))
    //     return <Navigate to="error?type=auth" />;

    return <Spinner fullScreen />;
}

export default PostSignupPage;
