import clsx from "clsx";
import { ErrorMessage } from "./ErrorMessage";
import { Label } from "./Label";
import InputMask from "react-input-mask";
import { InformationCircleIcon } from "@heroicons/react/24/solid";

type NativeInputProps = React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
>;

export function RawInput({
    name,
    label,
    errorMessage,
    showErrorRing,
    required,
    placeholder,
    mask,
    tooltip,
    ...props
}: {
    name: string;
    label: string;
    errorMessage?: string;
    showErrorRing?: boolean;
    placeholder?: string;
    mask?: string;
    tooltip?: string;
} & Omit<NativeInputProps, "name" | "label">) {
    const describedBy = props["aria-describedby"] || `${name}-error`;

    const InputComponent = mask ? InputMask : "input";

    return (
        <div className="flex flex-col">
            <div className="flex items-center justify-between">
                <Label name={name} label={label} required={required} />
                {tooltip && (
                    <div className="tt-trigger group hidden md:block">
                        <InformationCircleIcon className="size-4 fill-gray-500" />
                        <div className="tt-container tt-bottom">
                            <div className="tt">{tooltip}</div>
                        </div>
                    </div>
                )}
            </div>
            <InputComponent
                id={name}
                data-testid={`input-${name}`}
                name={name}
                mask={mask || ""}
                disabled={props.disabled}
                placeholder={placeholder || ""}
                className={clsx(
                    "border-default rounded border outline-none",
                    "placeholder:text-slate-500",
                    "focus:ring-transparent focus:placeholder:text-transparent",
                    (errorMessage || showErrorRing) &&
                        "ml-px ring-1 ring-red-600",
                    props.disabled && "cursor-not-allowed bg-white/20",
                )}
                aria-required={required}
                required={required}
                {...props}
            />
            <div
                className={clsx(
                    "overflow-hidden transition-all duration-300",
                    errorMessage ? "h-6 opacity-100" : "h-0 opacity-0",
                )}
            >
                {errorMessage && (
                    <ErrorMessage id={describedBy} message={errorMessage} />
                )}
            </div>
        </div>
    );
}
