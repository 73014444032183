export function isoDateFormat(month?: string, year?: string): string | null {
    // Check if month or year is undefined or null
    if (!month || !year) {
        return null;
    }

    const monthIndex = new Date(`${month} 1, 2000`).getMonth();
    if (isNaN(monthIndex)) {
        throw new Error("Invalid month provided");
    }

    const yearNumber = parseInt(year, 10);
    if (isNaN(yearNumber) || yearNumber < 1000 || yearNumber > 9999) {
        throw new Error("Invalid year provided");
    }

    const date = new Date(yearNumber, monthIndex, 1);

    return date.toISOString();
}

export function isoToDate(isoDateString: string, addZulu = false) {
    if (!isoDateString) return "";
    let date: Date;

    if (addZulu) {
        const cleanDateString = isoDateString.replace(/(\.\d{3})\d+/, "$1");
        date = new Date(cleanDateString + "Z");
    } else if (isoDateString.includes("T")) {
        date = new Date(isoDateString);
    } else {
        date = new Date(isoDateString + "T00:00:00");
    }
    if (isNaN(date.getTime())) return "";

    return date.toLocaleDateString("en-US", {
        month: "2-digit",
        day: "2-digit",
        year: "numeric",
    });
}
