import type { User } from "@/entities/User/User.model";
import type { Company } from "@/entities/Company/Company.types";
import {
    CompanyFileDocumentType,
    OnboardingStatus,
} from "@/entities/Company/Company.constants";
import { SENSITIVE_COUNTRIES } from "@/constants";

export const getOnboardingStatus = (user?: User | null, company?: Company) => {
    if (!user || !user.emailVerified) return OnboardingStatus.VERIFY_EMAIL;

    if (!user.organizationId) return OnboardingStatus.BASIC_INFO;

    if (!company) return OnboardingStatus.ABOUT_YOUR_BUSINESS;

    const { signupStatus } = company;

    const hasBusinessInfo = signupStatus.hasBasicBusinessInfo;

    if (!hasBusinessInfo) {
        return OnboardingStatus.ABOUT_YOUR_BUSINESS;
    } else if (
        company.country &&
        SENSITIVE_COUNTRIES.includes(company.country)
    ) {
        return OnboardingStatus.UNSUPPORTED_COUNTRY;
    }

    const hasFundraisingGoals = signupStatus.hasFundraisingGoals;

    if (!hasFundraisingGoals) return OnboardingStatus.SHARE_YOUR_GOALS;

    const hasFundraisingHistory = signupStatus.hasFundraisingHistory;

    if (!hasFundraisingHistory) return OnboardingStatus.FUNDRAISING_HISTORY;

    if (!company.acceptedNda) return OnboardingStatus.MNDA;

    const data = company.activationStatus;
    const missingFiles = data.missingFileTypesInDataroom || [];
    const isIncomeAndBalanceMissing =
        missingFiles.includes(CompanyFileDocumentType.IncomeStatement) &&
        missingFiles.includes(CompanyFileDocumentType.BalanceSheet);

    if (
        !(
            data.hasCompanyConfiguredConnector ||
            data.hasCompanyConfiguredDataRoom ||
            !isIncomeAndBalanceMissing
        ) &&
        signupStatus.complete
    ) {
        return OnboardingStatus.DATAROOM;
    }

    return OnboardingStatus.COMPLETE;
};
