import clsx from "clsx";
import { useSearchParams } from "react-router-dom";

export function ErrorPage() {
    const [searchParams] = useSearchParams();
    const isAuthError = searchParams.get("type") === "auth";

    const title = isAuthError
        ? "Your session has expired"
        : "Oops! Something went wrong";

    const message = isAuthError
        ? "For security reasons, your session has timed out. Please sign in again."
        : "There was a problem loading this page.";

    const buttonText = isAuthError ? "Sign In" : "Refresh Page";

    const handleButtonClick = () => {
        if (isAuthError) {
            window.location.href = "/login";
        } else {
            window.location.reload();
        }
    };

    return (
        <main
            className={clsx(
                "px-6 py-24",
                "grid min-h-full place-items-center",
                "sm:py-32 lg:px-8",
            )}
        >
            <div className="text-center">
                <h2 className="mt-4">{title}</h2>
                <p className="subdued mt-6 text-base leading-7">{message}</p>
                <div
                    className={clsx(
                        "mt-10",
                        "flex items-center justify-center gap-x-6",
                    )}
                >
                    <button onClick={handleButtonClick} className="sm">
                        {buttonText}
                    </button>
                    <a
                        href="mailto:hello@humcapital.com"
                        className="link text-sm font-semibold"
                    >
                        Contact support <span aria-hidden="true">&rarr;</span>
                    </a>
                </div>
            </div>
        </main>
    );
}
