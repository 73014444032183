import { ArrowDownIcon } from "@heroicons/react/20/solid";
import clsx from "clsx";

type ScrollIndicatorProps = {
    testId?: string;
    className?: string;
};

export function ScrollIndicator({ testId, className }: ScrollIndicatorProps) {
    return (
        <div
            className={clsx(
                className,
                "ui-element subdued",
                "bg-slate-50/10 px-6 py-1",
                "flex items-center gap-2",
                "rounded-full shadow-tooltip ring-1 ring-slate-200",
            )}
            data-testid={testId}
        >
            Scroll <ArrowDownIcon className="size-4" />
        </div>
    );
}
