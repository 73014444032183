import {
    Icon,
    InlineNotification,
    NotificationType,
    Section,
    Spinner,
} from "@/ui";
import { DiscardModal } from "./DiscardModal";
import { TrashIcon } from "@heroicons/react/20/solid";
import { useState } from "react";
import clsx from "clsx";
import { atom, useAtomValue } from "jotai";
import { PrivacyToggle } from "./PrivacyToggle";
import { ddActions } from "@/utils";

export enum ControlState {
    NotEditing = "NotEditing",
    Editing = "Editing",
    Preview = "Preview",
}

export const hasHeroErrorAtom = atom<boolean>(false);
export const hasFinancialErrorAtom = atom<boolean>(false);
export const heroErrorsAtom = atom<string[]>([]);
export const financialErrorsAtom = atom<string[]>([]);

export const Controls = ({
    isDirty = false,
    controlState,
    onEdit,
    onDiscard,
    onPreview,
    onPublish,
    onPrivate,
    sendingChanges,
}: {
    isDirty?: boolean;
    controlState?: ControlState;
    onEdit?: () => void;
    onDiscard?: () => void;
    onPreview?: () => void;
    onPublish?: () => void;
    onPrivate?: () => void;
    sendingChanges?: boolean;
}) => {
    const [privateProfile, setPrivateProfile] = useState(false);
    const [_controlState, setControlState] = useState(
        controlState || ControlState.NotEditing,
    );
    const [showDiscardModal, setShowDiscardModal] = useState(false);
    const heroError = useAtomValue(hasHeroErrorAtom);
    const financialSectionError = useAtomValue(hasFinancialErrorAtom);
    const hasErrors = heroError || financialSectionError;
    const heroErrors = useAtomValue(heroErrorsAtom);
    const financialErrors = useAtomValue(financialErrorsAtom);

    if (_controlState === ControlState.NotEditing) {
        return (
            <Section className="pb-0">
                <div className="flex justify-between px-2 md:px-0">
                    <PrivacyToggle
                        isPrivate={!!privateProfile}
                        onChange={() => {
                            setPrivateProfile(!privateProfile);
                            onPrivate && onPrivate();
                        }}
                    />
                    <button
                        onClick={() => {
                            setControlState(ControlState.Editing);
                            onEdit && onEdit();
                        }}
                        className="secondary xl flex items-center"
                        dd-action={ddActions.profile.edit.click}
                    >
                        <Icon.Pencil className="text-color mr-2" />
                        <p className="text-button">Edit Page</p>
                    </button>
                </div>
            </Section>
        );
    }

    return (
        <Section className="pb-0">
            <div
                className={clsx(
                    "flex items-center",
                    _controlState === ControlState.Preview
                        ? "justify-between"
                        : "justify-end",
                )}
            >
                {_controlState === ControlState.Preview && (
                    <PrivacyToggle
                        isPrivate={!!privateProfile}
                        onChange={() => {
                            setPrivateProfile(!privateProfile);
                            onPrivate && onPrivate();
                        }}
                    />
                )}
                <div
                    className={clsx(
                        "px-2",
                        "flex flex-wrap",
                        "items-center justify-end",
                        "md:px-0",
                    )}
                >
                    <button
                        className="secondary flex items-center"
                        onClick={() => {
                            if (isDirty) setShowDiscardModal(true);
                            else setControlState(ControlState.NotEditing);
                        }}
                        dd-action={ddActions.profile.edit.discard.click}
                    >
                        <TrashIcon className="text-color mr-2 size-4" />
                        <p className="text-button hidden md:block">Discard</p>
                    </button>

                    {_controlState === ControlState.Editing && (
                        <button
                            onClick={() => {
                                setControlState(ControlState.Preview);
                                onPreview && onPreview();
                            }}
                            className="ml-4"
                            disabled={hasErrors}
                            dd-action={ddActions.profile.edit.preview.click}
                        >
                            Preview Changes
                        </button>
                    )}

                    {_controlState === ControlState.Preview && (
                        <>
                            <button
                                onClick={() => {
                                    setControlState(ControlState.Editing);
                                    onEdit && onEdit();
                                }}
                                dd-action={ddActions.profile.edit.preview.back}
                                className="secondary ml-4 flex items-center"
                            >
                                <Icon.Pencil className="mr-2" />
                                <p className="text-button hidden md:block">
                                    Back to Editing
                                </p>
                            </button>

                            <button
                                onClick={() => {
                                    setControlState(ControlState.NotEditing);
                                    onPublish && onPublish();
                                }}
                                className="ml-4 inline-flex gap-2"
                                dd-action={ddActions.profile.edit.publish.click}
                            >
                                Publish Changes
                                {sendingChanges && <Spinner />}
                            </button>
                        </>
                    )}
                </div>
            </div>

            {showDiscardModal && (
                <DiscardModal
                    onDiscard={() => {
                        setShowDiscardModal(false);
                        setControlState(ControlState.NotEditing);
                        onDiscard && onDiscard();
                    }}
                    onCancel={() => {
                        setShowDiscardModal(false);
                    }}
                />
            )}
            {hasErrors && (
                <InlineNotification
                    className="translate-y-4"
                    type={NotificationType.WARNING}
                >
                    There are problems in the form below:
                    {heroErrors.length > 0 && (
                        <div className="mt-2">
                            <ul className="list-disc pl-5">
                                {heroErrors.map((error, idx) => (
                                    <li className="text-[#151C23]" key={idx}>
                                        {error}
                                    </li>
                                ))}
                                {financialErrors.length > 0 && (
                                    <li className="text-[#151C23]">
                                        {financialErrors.map((error, idx) => (
                                            <li key={idx}>{error}</li>
                                        ))}
                                    </li>
                                )}
                            </ul>
                        </div>
                    )}
                </InlineNotification>
            )}
        </Section>
    );
};
