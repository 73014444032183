/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { log } from "@/utils";
import { AxiosError, AxiosResponse } from "axios";
import _ from "lodash";

type NetworkError = AxiosError & { response: AxiosResponse };

export type ErrorHandler = (error: NetworkError) => any;

/**
 * Composes multiple data handlers into a single, ordered handling mechanism.
 *
 * @param handlers A list of data handlers.
 */
const composeHandlers =
    (...handlers: Function[]) =>
    async (...args: unknown[]) => {
        let resolved;

        for (const handler of handlers) {
            // eslint-disable-next-line no-await-in-loop
            if ((resolved = await handler(...args)) !== undefined) {
                // if handler resolved, shortcut exit.
                return resolved;
            }
        }

        // if no handler resolved/threw, resolve to parent root.
        return args[0];
    };

export { composeHandlers };

/**
 * Composes multiple error handlers into a single, ordered handling mechanism.
 *
 * @param handlers A list of error handlers.
 */
const composeErrorHandlers = (...handlers: ErrorHandler[]) => {
    const handle = composeHandlers(...handlers);

    return async (error: Error) => {
        const resolved = await handle(error);

        // Return resolved value, if any.
        if (resolved !== undefined && resolved !== error) {
            return resolved;
        }

        // Rethrow unhandled error.
        sendError(error);
    };
};

/**
 * Handles failed HTTP requests.
 *
 * i.e.: backend not available.
 */
const handleRejectedNetworkError = (error: NetworkError) => {
    if (!error.response) {
        sendError(error);
    }
};

/**
 * Handles HTTP error responses with a { data: { message } } structure.
 *
 * i.e.: 400 :: { data: { message: 'Invalid credentials' } }
 */

const handleRejectedErrorMessage = (v: NetworkError) => {
    const message = v?.response?.data?.message;
    if (message) {
        sendError(new Error(message));
    }
};

/**
 * Handles HTTP error responses with a { data: { error } } structure.
 *
 * i.e.: 400 :: { data: { error: 'Invalid credentials' } }
 */
const handleRejectedError = ({ response }: NetworkError) => {
    if (response && response.data.error) {
        sendError(new Error(response.data.error));
    }
};

/**
 * Handles HTTP error responses with a { data: { code, description, name } } structure.
 *
 * i.e.: 401 :: { code: 401, name: 'Unauthorized', description: 'The server could not verify...' }
 */

const handleRejectedDescription = (v: any) => {
    const data: {
        code: number;
        name: string;
        description: string | { message: string };
    } = v?.response?.data;

    if (data && data.code) {
        const messages = [
            // in case description is the error string itself
            data.description,
            // in case description is an object containing the message
            typeof data.description === "object"
                ? data.description.message
                : null,
            // in case we have a name
            data.name,
            // fallback
            "Unexpected error",
        ];

        sendError(
            new Error(
                messages.find((option) => typeof option === "string") as string,
            ),
        );
    }
};

/**
 * Default prioritize error handler.
 */
const handleErrors = composeErrorHandlers(
    handleRejectedNetworkError,
    handleRejectedError,
    handleRejectedErrorMessage,
    handleRejectedDescription,
);

/**
 * Handles HTTP success messages containing an error.
 *
 * i.e.: 200 :: { data: { error: 'Error validating against schema' } }
 */
const handleRespondedError = ({ data }: { data: any }) => {
    if (data?.error) {
        sendError(new Error(data.error));
    }
};

/**
 * Handles HTTP success messages containing and error in array structure.
 *
 * i.e.: 200 :: { data: [{ message: 'user taken' }, 400] }
 */
const handleRespondedErrorArray = ({ data }: { data: any }) => {
    if (data?.[0]?.message && data[1] && data[1] === 400) {
        sendError(new Error(data[0].message));
    }
};

/**
 * Default prioritize response with error as data (200) handler.
 */
const handleRespondedErrors = composeHandlers(
    handleRespondedError,
    handleRespondedErrorArray,
);

export {
    composeErrorHandlers,
    handleErrors,
    handleRejectedNetworkError,
    handleRejectedError,
    handleRejectedErrorMessage,
    handleRejectedDescription,
    handleRespondedErrors,
    handleRespondedError,
    handleRespondedErrorArray,
};

const sendError = (error: Error | AxiosError<any>) => {
    try {
        if ("response" in error) {
            log.error(
                `${error.response?.config.method} ${error.response?.config.url} : ${error.response?.status} ${error.response?.statusText}`,
                {
                    status: error.response?.status,
                    statusText: error.response?.statusText,
                    data: error.response?.data,
                    config: error.response?.config
                        ? {
                              url: error.response?.config.url,
                              method: error.response?.config.method,
                              params: error.response?.config.params,
                              headers: _.omit(
                                  error.response?.config.headers || {},
                                  "Authorization",
                              ),
                          }
                        : undefined,
                    message: error.message,
                },
            );
        } else {
            // For non-Axios errors
            const errorInfo = {
                name: error.name,
                message: error.message,
                stack: error.stack,
            };
            log.error("Application Error", errorInfo);
        }
    } catch (loggingError) {
        log.error("Error Logging Failed", {
            originalError: String(error),
            loggingError: String(loggingError),
        });
    }
    throw error;
};
